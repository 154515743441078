import api from "./api";

import config from "../config/config";

export default async function validateUserSession() {
  if (sessionStorage.getItem("user")) return;

  const token = localStorage.getItem("sessionToken");
  if (!token) return (window.location.href = config.url.login);

  try {
    const res = await api.get("/session/status", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const user = JSON.stringify(res.data.user);
    sessionStorage.setItem("user", user);
  } catch (err) {
    window.location.href = config.url.login;
    localStorage.removeItem("sessionStorage");
  }
}
