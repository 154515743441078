import React, { useEffect } from "react";
import { FiDownload } from "react-icons/fi";

import "./style.sass";

export default function DwnloadTableButton({ color, downloadURL }) {
  useEffect(() => {
    const tableContainer = document.querySelector(".table-container");
    const resultsTable = document.querySelector(".results-table");
    const downloadButton = document.querySelector(".download-table-button");
    if (!tableContainer || !resultsTable || !downloadButton) return;

    function resizeHandler(e) {
      const { width: containerWidth } = tableContainer.getBoundingClientRect();
      const { width: tableWidth } = resultsTable.getBoundingClientRect();

      if (!containerWidth || !tableWidth) return;

      downloadButton.style.setProperty(
        "width",
        tableWidth > containerWidth ? `${containerWidth}px` : `${tableWidth}px`
      );
    }

    const resizeObserver = new ResizeObserver(resizeHandler);
    resizeObserver.observe(resultsTable);
    resizeObserver.observe(tableContainer);

    return () => {
      resizeObserver.unobserve(resultsTable);
      resizeObserver.unobserve(tableContainer);
    };
  }, []);

  return (
    <div className=" download-table-button">
      <a
        className="border-medium border-medium-hover dark download-button"
        href={downloadURL}
        download
      >
        <FiDownload size="20" color={color} />
        <span>Baixar Tabela</span>
      </a>
    </div>
  );
}
