import React, { useEffect } from "react";

import "./style.sass";

function createPagingMap(page = 0, pages = 0) {
  const pagingMap = [];

  if (pages <= 0) return pagingMap;

  if (pages <= 4) {
    for (let i = 1; i <= pages; i += 1) {
      pagingMap.push({ index: i, name: `${i}`, selected: i === page });
    }
    return pagingMap;
  }

  pagingMap.push({ index: 1, name: "Primeira", disabled: page === 1 });

  const startIndex = page === 1 ? 1 : page + 1 > pages ? page - 2 : page - 1;
  const endIndex = startIndex + 2;
  for (let i = startIndex; i <= endIndex; i += 1) {
    pagingMap.push({ index: i, name: `${i}`, selected: page === i });
  }
  pagingMap.push({ index: pages, name: "Última", disabled: page === pages });
  return pagingMap;
}

export default function PaginButtons({
  page,
  pages,
  total,
  onPageChange,
  results,
}) {
  const pagingMap = createPagingMap(page, pages);

  useEffect(() => {
    const tableContainer = document.querySelector(".table-container");
    const resultsTable = document.querySelector(".results-table");
    const tablePaging = document.querySelector(".table-paging-footer");
    if (!tableContainer || !resultsTable || !tablePaging) return;

    function resizeHandler(e) {
      const { width: containerWidth } = tableContainer.getBoundingClientRect();
      const { width: tableWidth } = resultsTable.getBoundingClientRect();

      if (!containerWidth || !tableWidth) return;

      tablePaging.style.setProperty(
        "width",
        tableWidth > containerWidth ? `${containerWidth}px` : `${tableWidth}px`
      );
    }

    const resizeObserver = new ResizeObserver(resizeHandler);
    resizeObserver.observe(resultsTable);
    resizeObserver.observe(tableContainer);

    return () => {
      resizeObserver.unobserve(resultsTable);
      resizeObserver.unobserve(tableContainer);
    };
  }, []);

  function handleButton(index) {
    if (onPageChange) {
      onPageChange(index);
    }
  }

  const rows = results ? results.length : 0;

  const message = parseInt(total, 10)
    ? `Exibindo ${rows} de ${total} resultados`
    : "Nenhum resultado encontrado";

  return (
    <div className="table-paging-footer">
      <div className="message pre-dark">{message}</div>
      <div className="buttons-container">
        {pagingMap.map((p) => (
          <span
            key={`${p.index}-${p.name}`}
            onClick={() => handleButton(p.index)}
            className={`buttons ${
              p.selected
                ? "selected reverse bg-dark"
                : p.disabled
                ? "disabled"
                : "pre-dark"
            }`}
          >
            {p.name}
          </span>
        ))}
      </div>
    </div>
  );
}
