import React, { memo } from "react";

import nonlocaleSplit from "../../util/nonlocaleSplit";
import formatDate from "../../util/formatDate";
import "./style.sass";

const translation = {
  enneagram: [
    ["Nome", "name", "name"],
    ["Email", "email", "long-string"],
    ["Telefone", "phone", "phone"],
    ["Cidade", "city", "long-string"],
    ["Estado", "state", "state"],
    ["Data", "created_at", "date"],
    ["Perfeccionista", "perfectionist", "number"],
    ["Ajudante", "helper", "number"],
    ["Vencedor", "achiever", "number"],
    ["Intenso", "romantic", "number"],
    ["Analítico", "investigator", "number"],
    ["Precavido", "loyalist", "number"],
    ["Otimista", "enthusiast", "number"],
    ["Poderoso", "challanger", "number"],
    ["Mediador", "mediator", "number"],
  ],
  disc: [
    ["Nome", "name", "name"],
    ["Email", "email", "long-string"],
    ["Telefone", "phone", "phone"],
    ["Cidade", "city", "long-string"],
    ["Estado", "state", "state"],
    ["Data", "created_at", "date"],
    ["Dominante", "dominance", "number"],
    ["Influente", "influence", "number"],
    ["Estável", "steadiness", "number"],
    ["Cauteloso", "conscientiousness", "number"],
  ],
};

function makeArray(length) {
  const array = [];
  for (let i = 0; i < length; i += 1) {
    array[i] = null;
  }
  return array;
}

function formatCell(cell, type = "", name = "") {
  if (!cell) {
    return "\u00A0";
  }

  if (type === "date") {
    return formatDate(cell);
  }

  if (type === "name" && name && cell.length >= name.length) {
    return highlightString(cell, name);
  }

  return cell;
}

function highlightString(string, search) {
  const splittedString = nonlocaleSplit(string, search);
  return splittedString.map((e, i) => (
    <span
      key={`${i}-${e.value}`}
      className={e.type === "token" ? "highlight" : "prewrap"}
    >
      {e.value}
    </span>
  ));
}

export default memo(function ResultsTable({
  results = [],
  test = "",
  size = 10,
  name = "",
}) {
  return (
    <div className="results-table">
      <div className="table-container">
        {test &&
          translation[test].map((c) => (
            <div key={`${test}-${c[1]}`} className="column">
              <header className="title dark border-light bg-light">
                {c[0]}
              </header>
              <>
                {results.map((r) => (
                  <div
                    key={`${test}-${c[1]}-${r.id}`}
                    className={`cell ${
                      c[2] || ""
                    } medium border-light bg-light`}
                  >
                    {formatCell(r[c[1]], c[2], name)}
                  </div>
                ))}
              </>
              <>
                {makeArray(size - results.length).map((e, i) => (
                  <div
                    key={`${c[1]}-${i}`}
                    className={`cell ${
                      c[2] || ""
                    } medium border-light bg-light`}
                  >
                    &nbsp;
                  </div>
                ))}
              </>
            </div>
          ))}
      </div>
    </div>
  );
});
