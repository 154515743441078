import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Results from "./pages/Results";
import Edit from "./pages/Edit";
import Users from "./pages/Users";
import Logout from "./pages/Logout";

import validateUserSession from "./util/validateUserSession";
import getQueryParams from "./util/getQueryParams";
import colors from "./config/colors";

export default function Routes() {
  const [user, setUser] = useState(null);
  const [colorScheme, setColorScheme] = useState(localStorage.getItem("colorScheme") || "default");

  useEffect(() => {
    const { token } = getQueryParams();
    if (token) {
      localStorage.setItem("sessionToken", token);
      window.history.replaceState(null, "", "/");
    }
    validateUserSession().then(() => {
      const user = sessionStorage.getItem("user");
      setUser(JSON.parse(user));
    });
  }, []);

  useEffect(() => {
    sessionStorage.removeItem("results");
    sessionStorage.removeItem("users");
    sessionStorage.removeItem("filters");
    sessionStorage.removeItem("profile");
  }, []);

  const color = colors[colorScheme] || colors.default;
  const className = colorScheme ? `${colorScheme}-color-scheme` : "default-color-scheme";

  document.querySelector("#root").setAttribute("class", className);

  function handleColorSchemeChange(scheme) {
    if (!colors[scheme]) return;

    localStorage.setItem("colorScheme", scheme);
    setColorScheme(scheme);
  }

  return (
    user && (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route
            path={["/", "/resultados"]}
            exact
            render={() => (
              <Results user={user} color={color} changeColorScheme={handleColorSchemeChange} />
            )}
          />
          {user.role === "admin" && (
            <Route
              path="/usuarios"
              exact
              render={() => (
                <Users user={user} color={color} changeColorScheme={handleColorSchemeChange} />
              )}
            />
          )}
          {(user.role === "editor" || user.role === "admin") && (
            <Route
              path="/editar"
              exact
              render={() => (
                <Edit user={user} color={color} changeColorScheme={handleColorSchemeChange} />
              )}
            />
          )}
          <Route
            path="/sair"
            exact
            render={() => (
              <Logout user={user} color={color} changeColorScheme={handleColorSchemeChange} />
            )}
          />
        </Switch>
      </BrowserRouter>
    )
  );
}
