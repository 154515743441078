import React, { useState, useEffect } from "react";
import { BsNewspaper } from "react-icons/bs";
import { FiMapPin } from "react-icons/fi";
import { BsCalendar } from "react-icons/bs";
import { BiFace } from "react-icons/bi";
import { FiArrowLeft } from "react-icons/fi";
import { GrClearOption } from "react-icons/gr";
import { RiUserSearchLine } from "react-icons/ri";

import "./style.sass";
import brazilianCities from "../../util/brazilianCities.json";

const profiles = {
  enneagram: [
    ["Perfeccionista", "perfectionist"],
    ["Ajudante", "helper"],
    ["Vencedor", "achiever"],
    ["Intenso", "romantic"],
    ["Analítico", "investigator"],
    ["Precavido", "loyalist"],
    ["Otimista", "enthusiast"],
    ["Poderoso", "challanger"],
    ["Mediador", "mediator"],
  ],
  disc: [
    ["Dominante", "dominance"],
    ["Influente", "influence"],
    ["Estável", "steadiness"],
    ["Cauteloso", "conscientiousness"],
  ],
};

export default function ResultsFilter({
  values,
  setValues,
  clearFilters,
  color,
}) {
  const [show, setShow] = useState(true);

  function handleFormChange(e) {
    const value = {};
    value[e.target.name] = e.target.value;
    setValues({ ...values, ...value, page: "1" });
  }

  function handleTestChange(e) {
    const value = { test: e.target.value, profile: "" };
    setValues({ ...values, ...value, page: "1" });
  }

  function handleStateChange(e) {
    const value = { state: e.target.value, city: "" };
    setValues({ ...values, ...value, page: "1" });
  }

  function handleNumberChange(e) {
    const value = {};
    value[e.target.name] = e.target.value;
    if (e.target.value >= 0 && e.target.value <= 100) {
      setValues({ ...values, ...value, page: "1" });
    }
  }

  function handleToggler(e) {
    setShow(!show);
  }

  function handleClearButton() {
    clearFilters && clearFilters();
  }

  useEffect(() => {
    const path = document.querySelector("#clear-icon").children[0];
    path.setAttribute("stroke", color);
  }, [color]);

  useEffect(() => {
    const filter = document.querySelector(".results-filter");
    if (!filter) return;

    let start = null;
    let lastTouchDiff = null;
    const prev = {};

    function touchStartHandler(e) {
      if (e.touches.length) start = e.touches[0];

      prev.td = filter.style.getPropertyValue("transition-duration");
      prev.ml = filter.getBoundingClientRect().x;
      filter.style.setProperty("transition-duration", "0ms");
    }

    function touchEndHandler(e) {
      filter.style.setProperty("transition-duration", prev.td);

      const ml = parseInt(filter.style.getPropertyValue("margin-left"), 10);
      const diff = ml - prev.ml;
      const minScrollToToggle = 50;

      if (allowDrag(lastTouchDiff) && Math.abs(diff) >= minScrollToToggle) {
        setShow(diff > 0);
        filter.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      filter.style.setProperty("margin-left", "");
      start = null;
    }

    function getTouchDiff(e) {
      const touch = e.touches[0];

      if (touch) {
        lastTouchDiff = {
          x: touch.clientX - start.clientX,
          y: start.clientY - touch.clientY,
        };
      }

      return lastTouchDiff || { x: 0, y: 0 };
    }

    function allowDrag(diff) {
      return diff && Math.abs(diff.x) > Math.abs(diff.y) * 2;
    }

    function touchMoveHandler(e) {
      const diff = getTouchDiff(e);
      const prevMl = parseInt(prev.ml, 10) || 0;
      let newMl = prevMl + diff.x;
      newMl = newMl < 0 ? newMl : 0;

      if (allowDrag(diff)) {
        filter.style.setProperty("margin-left", `${newMl}px`);
      }
    }

    const passive = false;
    filter.addEventListener("touchstart", touchStartHandler, { passive });
    filter.addEventListener("touchend", touchEndHandler);
    filter.addEventListener("touchmove", touchMoveHandler, { passive });

    return () => {
      filter.removeEventListener("touchstart", touchStartHandler);
      filter.removeEventListener("touchend", touchEndHandler);
      filter.removeEventListener("touchmove", touchMoveHandler);
    };
  }, []);

  return (
    <div className="results-filter bg-light" data-show={show}>
      <button className="toggler bg-dark" onClick={handleToggler}>
        <FiArrowLeft size="24" color="#fff" />
      </button>
      <form>
        <section className="border-light bg-light">
          <header>
            <BsNewspaper size="24" color={color} />
            <span className="dark">Teste</span>
          </header>
          <div className="input-container input-radio-container">
            <input
              className="border-medium border-medium-hover bg-light"
              type="radio"
              name="test"
              id="enneagram-radio"
              value="enneagram"
              onChange={handleTestChange}
              checked={values.test === "enneagram"}
            />
            <label className="medium" htmlFor="enneagram-radio">
              Eneagrama
            </label>
          </div>
          <div className="input-container input-radio-container">
            <input
              className="border-medium border-medium-hover bg-light"
              type="radio"
              name="test"
              id="disc-radio"
              value="disc"
              onChange={handleTestChange}
              checked={values.test === "disc"}
            />
            <label className="medium" htmlFor="disc-radio">
              DISC
            </label>
          </div>
        </section>

        <section className="border-light bg-light">
          <header>
            <RiUserSearchLine size="24" color={color} />
            <span className="dark">Pessoas</span>
          </header>

          <div className="input-container">
            <label className="medium" htmlFor="name">
              nome
            </label>
            <input
              className="border-medium border-medium-hover bg-light medium"
              id="name"
              type="text"
              name="name"
              value={values.name}
              onChange={handleFormChange}
            />
          </div>
        </section>
        <section className="border-light bg-light">
          <header>
            <FiMapPin size="24" color={color} />
            <span className="dark">Localização</span>
          </header>
          <div className="input-container">
            <label className="medium" htmlFor="state-select">
              estado
            </label>
            <select
              className="border-medium border-medium-hover bg-light medium"
              id="state-select"
              name="state"
              value={values.state}
              onChange={handleStateChange}
            >
              <option value=""></option>
              <>
                {brazilianCities.states.map((state) => (
                  <option key={state.abbreviation} value={state.abbreviation}>
                    {state.name} ({state.abbreviation})
                  </option>
                ))}
              </>
            </select>
          </div>
          <div className="input-container">
            <label className="medium" htmlFor="city-select">
              cidade
            </label>
            <select
              className="border-medium border-medium-hover bg-light medium"
              id="city-select"
              name="city"
              value={values.city}
              onChange={handleFormChange}
            >
              <option value=""></option>
              <>
                {values.state &&
                  brazilianCities.states
                    .find((s) => s.abbreviation === values.state)
                    .cities.map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
              </>
            </select>
          </div>
        </section>
        <section className="border-light bg-light">
          <header>
            <BsCalendar size="24" color={color} />
            <span className="dark">Data</span>
          </header>
          <div className="input-container">
            <label className="medium" htmlFor="date_start">
              de
            </label>
            <input
              className="border-medium border-medium-hover bg-light medium"
              id="date_start"
              type="date"
              name="date_start"
              value={values.date_start}
              onChange={handleFormChange}
            />
          </div>
          <div className="input-container">
            <label className="medium" htmlFor="date_end">
              até
            </label>
            <input
              className="border-medium border-medium-hover bg-light medium"
              id="date_end"
              type="date"
              name="date_end"
              value={values.date_end}
              onChange={handleFormChange}
            />
          </div>
        </section>
        <section className="border-light bg-light">
          <header>
            <BiFace size="24" color={color} />
            <span className="dark">Personalidade</span>
          </header>
          <div className="input-container">
            <label className="medium" htmlFor="profile-select">
              tipo
            </label>
            <select
              className="border-medium border-medium-hover bg-light medium"
              id="profile-select"
              name="profile"
              value={values.profile}
              onChange={handleFormChange}
            >
              <option value=""></option>
              <>
                {profiles[values.test].map((p) => (
                  <option key={p[1]} value={p[1]}>
                    {p[0]}
                  </option>
                ))}
              </>
            </select>
          </div>
          <div className="input-container">
            <label className="medium" htmlFor="points_min-input">
              min
            </label>
            <input
              className="border-medium border-medium-hover bg-light medium"
              id="points_min-input"
              type="number"
              min="0"
              max="100"
              step="1"
              name="points_min"
              value={values.points_min}
              onChange={handleNumberChange}
            />
          </div>
          <div className="input-container">
            <label className="medium" htmlFor="points_max-input">
              max
            </label>
            <input
              className="border-medium border-medium-hover bg-light medium"
              id="points_max-input"
              type="number"
              min="0"
              max="100"
              step="1"
              name="points_max"
              value={values.points_max}
              onChange={handleNumberChange}
            />
          </div>
        </section>
        <section className="border-light bg-light">
          <div
            className="clearButton border-medium border-medium-hover"
            onClick={handleClearButton}
          >
            <GrClearOption size="20" color={color} id="clear-icon" />
            <span className="dark">Limpar filtros</span>
          </div>
        </section>
      </form>
    </div>
  );
}
