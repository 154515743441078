import React from "react";
import { BsNewspaper } from "react-icons/bs";
import { BiFace } from "react-icons/bi";

import "./style.sass";

export default function EditProfileForm({
  color,
  value,
  onTestChange,
  onProfileChange,
  profiles,
}) {
  function handleProfileChange(e) {
    onProfileChange && onProfileChange(e.target.value);
  }

  function handleTestChange(e) {
    onTestChange && onTestChange(e.target.value);
  }

  return (
    <form className="edit-profile-form">
      <section className="form-section">
        <header className="section-header dark">
          <BsNewspaper size="24" color={color} />
          <span className="title">Test</span>
        </header>
        <div className="input-container input-radio-container">
          <input
            id="enneagram"
            type="radio"
            name="test"
            value="enneagram"
            onChange={handleTestChange}
            checked={value.test === "enneagram"}
            className="medium"
          />
          <label htmlFor="enneagram" className="medium">
            Eneagrama
          </label>
        </div>
        <div className="input-container input-radio-container">
          <input
            id="disc"
            type="radio"
            name="test"
            value="disc"
            onChange={handleTestChange}
            className="medium"
            checked={value.test === "disc"}
          />
          <label htmlFor="disc" className="medium">
            DISC
          </label>
        </div>
      </section>
      <section className="form-section">
        <header className="section-header dark">
          <BiFace size="24" color={color} />
          <span className="title">Personalidade</span>
        </header>
        <div className="input-container input-select-container">
          <label htmlFor="profile" className="medium">
            tipo
          </label>
          <select
            id="profile"
            name="profile"
            onChange={handleProfileChange}
            value={value.profile}
            className="medium border-medium border-medium-hover"
          >
            {profiles[value.test].map((p) => (
              <option key={`${value.test}-${p[1]}`} value={p[1]}>
                {p[0]}
              </option>
            ))}
          </select>
        </div>
      </section>
    </form>
  );
}
