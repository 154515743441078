export default function getQueryParams() {
  const { href } = window.location;

  const queryStartIndex = href.indexOf("?");
  if (queryStartIndex < 0) return {};

  const hashStartIndex = href.indexOf("#");
  const queryString =
    hashStartIndex >= 0
      ? href.slice(queryStartIndex + 1, hashStartIndex)
      : href.slice(queryStartIndex + 1);

  const queryKeyValuePair = queryString.split("&");

  const queries = {};
  queryKeyValuePair.map((e) => {
    const [key, value] = e.split("=");
    return (queries[key] = value);
  });

  return queries;
}
